import {
  CARRELLO_SUCCESS,
  CARRELLO_START,
  CARRELLO_FAIL,
  AGGIUNGI_PRODOTTO_CART,
  INCREMENTA_PRODOTTO,
  DECREMENTA_PRODOTTO,
  RECUPERA_CARRELLO,
  SET_COPERTI,
  GET_PRODOTTI_START,
  GET_PRODOTTI_SUCCESS,
  GET_PRODOTTI_FAIL,
  SEND_ORDINE_START,
  SEND_ORDINE_SUCCESS,
  SEND_ORDINE_FAIL,
  SET_TOTALE_GENERALE,
  ISCARRELLO,
  SET_STORICO,
  GET_STORICO_ORDINi_START,
  GET_STORICO_ORDINi_SUCCESS,
  GET_STORICO_ORDINi_FAIL,
  GET_OFFERTE_START,
  GET_OFFERTE_SUCCESS,
  GET_OFFERTE_FAIL,
  GET_INGREDIENTI_START,
  GET_INGREDIENTI_SUCCESS,
  GET_INGREDIENTI_FAIL,
  SET_TAVOLO,
  SET_MENU,
  SET_IDORDINE,
  SET_IDFORMULA,
  GET_FORMULE_START,
  GET_FORMULE_FAIL,
  GET_FORMULE_SUCCESS,
  SET_STORICO_USCITE_ORDINE,
  SET_TIPO,
  RESET_CARRELLO,
} from "../actions/ordini";

const initialState = {
  prodotti: [],
  categorie: [],
  carrello: [],
  offerte: [],
  formule: [],
  ingredienti: [],
  qty_prodotti: 0,
  qty_bibite: 0,
  tot_prezzo: 0.0,
  tot_generale: 0.0,
  coperti: 0,
  storico: 0,
  storico_uscite: "",
  storico_ordini: [],
  id_ordine: "",
  id_formula: "",
  tavolo: "",
  loading: false,
  iscarrello: false,
  error: false,
  togglemenu: true,
  id_formula: "",
  tipo: "",
};

function ordini(state = initialState, action) {
  switch (action.type) {
    case ISCARRELLO:
      return {
        ...state,
        iscarrello: action.value,
      };
    case SET_STORICO_USCITE_ORDINE:
      console.log("setto storico");
      return {
        ...state,
        storico_uscite: action.value,
      };
    case GET_PRODOTTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODOTTI_SUCCESS:
      return {
        ...state,
        prodotti: action.res.data.prodotti.length>0 ? action.res.data.prodotti[0].prodottis :state.prodotti,
        loading: false,
        error: false,
      };
    case GET_PRODOTTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_OFFERTE_START:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFERTE_SUCCESS:
      return {
        ...state,
        prodotti: action.res.data.prodotti,
        loading: false,
        error: false,
      };
    case GET_OFFERTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_FORMULE_START:
      return {
        ...state,
        loading: true,
      };
    case GET_FORMULE_SUCCESS:
      return {
        ...state,
        formule: action.res.data.formule,
        loading: false,
        error: false,
      };
    case GET_FORMULE_FAIL:
      return {
        ...state,
        error: true,
      };
    case GET_INGREDIENTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_INGREDIENTI_SUCCESS:
      return {
        ...state,
        ingredienti: action.res.data.ingredienti,
        loading: false,
        error: false,
      };
    case GET_INGREDIENTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_STORICO_ORDINi_START:
      return {
        ...state,
        loading: true,
      };
    case GET_STORICO_ORDINi_SUCCESS:
      return {
        ...state,
        storico_ordini: action.res.data.storico,
        loading: false,
        error: false,
      };
    case GET_STORICO_ORDINi_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

      case RESET_CARRELLO:
        return {
          ...state,
          carrello:[],
          qty_prodotti:0,
          qty_bibite:0
        };
    case CARRELLO_START:
      return {
        ...state,
        loading: true,
      };
    case CARRELLO_SUCCESS:
      return {
        ...state,
        carrello: action.res.data.carrello,
        loading: false,
        error: false,
      };
    case CARRELLO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SEND_ORDINE_START:
      return {
        ...state,
        loading: true,
      };
    case SEND_ORDINE_SUCCESS:
      return {
        ...state,
        carrello: [],
        qty_prodotti: 0.0,
        tot_prezzo: 0.0,
        loading: false,
        error: false,
      };
    case SEND_ORDINE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SET_TOTALE_GENERALE:
      return {
        ...state,
        tot_generale: action.tot_generale,
      };
    case SET_TIPO:
      return {
        ...state,
        tipo: action.tipo,
      };
    case DECREMENTA_PRODOTTO:
      let prodotto2 = state.carrello.find(
        (p) => p.id_prodotto_interno === action.id_prodotto_interno
      );

      if (prodotto2.quantita == 1) {
        console.log("quantita ", prodotto2.quantita);
        return {
          ...state,
          carrello: [...state.carrello.filter((p) => p !== prodotto2)],
          qty_prodotti: state.qty_prodotti - 1,
          qty_bibite:
            prodotto2.categoria == 1 && prodotto2.prezzo == 0
              ? state.qty_bibite - 1
              : state.qty_bibite,

          tot_prezzo: state.tot_prezzo - prodotto2.prezzo,
        };
      } else {
        return {
          ...state,
          carrello: state.carrello.map((content, i) =>
            content.id_prodotto_interno === action.id_prodotto_interno
              ? { ...content, quantita: content.quantita - 1 }
              : content
          ),
          qty_prodotti: state.qty_prodotti - 1,
          qty_bibite:
            prodotto2.categoria == 1 && prodotto2.prezzo == 0
              ? state.qty_bibite - 1
              : state.qty_bibite,

          tot_prezzo: state.tot_prezzo - prodotto2.prezzo,
        };
      }

    case AGGIUNGI_PRODOTTO_CART:
      if (action.card.special == 0) {
        let prodotto = state.carrello.find(
          (p) =>
            p.id_prodotto === action.card.id_prodotto &&
            p.special == 0 &&
            p.uscita === action.card.uscita
        );
        if (prodotto) {
          return {
            ...state,
            carrello: [
              ...state.carrello.filter((p) => p !== prodotto),
              {
                ...prodotto,
                quantita: prodotto.quantita + 1 * action.card.quantita,
              },
            ],
            qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
            qty_bibite:
              action.card.categoria == 1 && action.card.prezzo == 0
                ? state.qty_bibite + 1 * action.card.quantita
                : state.qty_bibite,
            tot_prezzo:
              state.tot_prezzo + action.card.prezzo * action.card.quantita,
          };
        } else {
          return {
            ...state,
            carrello: [...state.carrello, action.card],
            qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
            qty_bibite:
              action.card.categoria == 1 && action.card.prezzo == 0
                ? state.qty_bibite + 1 * action.card.quantita
                : state.qty_bibite,
            tot_prezzo:
              state.tot_prezzo + action.card.prezzo * action.card.quantita,
          };
        }
      } else if (action.card.special == 1) {
        return {
          ...state,
          carrello: [...state.carrello, action.card],
          qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
          qty_bibite:
            action.card.categoria == 1 && action.card.prezzo == 0
              ? state.qty_bibite + 1 * action.card.quantita
              : state.qty_bibite,
          tot_prezzo:
            state.tot_prezzo + action.card.prezzo * action.card.quantita,
        };
      } else {
        return {
          ...state,
          carrello: [...state.carrello, action.card],
          qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
          qty_bibite:
            action.card.categoria == 1 && action.card.prezzo == 0
              ? state.qty_bibite + 1 * action.card.quantita
              : state.qty_bibite,
          tot_prezzo:
            state.tot_prezzo + action.card.prezzo * action.card.quantita,
        };
      }
    case INCREMENTA_PRODOTTO:
      console.log("incremento il prodotto: ", action.id_prodotto_interno);
      let prodotto = state.carrello.find(
        (p) => p.id_prodotto_interno === action.id_prodotto_interno
      );
      return {
        ...state,
        carrello: state.carrello.map((content, i) =>
          content.id_prodotto_interno === action.id_prodotto_interno
            ? { ...content, quantita: content.quantita + 1 }
            : content
        ),
        qty_prodotti: state.qty_prodotti + 1,
        qty_bibite:
          prodotto.categoria == 1 && prodotto.prezzo == 0
            ? state.qty_bibite + 1
            : state.qty_bibite,

        tot_prezzo: state.tot_prezzo + prodotto.prezzo,
        //        tot_prezzo: state.tot_prezzo + (prodotto.prezzo_offerte != 0.0 && prodotto.prezzo_offerte !== undefined ? prodotto.prezzo_offerte : prodotto.prezzo) ,
      };
    case RECUPERA_CARRELLO:
      return {
        ...state,
        carrello: [...action.carrello],
        qty_prodotti: action.qty,
        tot_prezzo: action.totale,
        qty_bibite: action.qty_bibite,
      };

    case SET_COPERTI:
      return {
        ...state,
        coperti: action.coperti,
      };

    case SET_STORICO:
      return {
        ...state,
        storico: action.value,
      };
    case SET_TAVOLO:
      return {
        ...state,
        tavolo: action.value,
      };
    case SET_IDORDINE:
      return {
        ...state,
        id_ordine: action.value,
      };
    case SET_IDFORMULA:
      return {
        ...state,
        id_formula: action.value,
      };
    case SET_MENU:
      return {
        ...state,
        togglemenu: action.value,
      };

    default:
      return state;
  }
}

export default ordini;
