import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { node } from "../Axios";
import Navbar from "../components/Navbar";
import { loading_login } from "../store/actions/login";
import { set_iscarrello } from "../store/actions/ordini";
import "../style/Home.css";
import { getSession } from "../utils/session";
import Modal from "./ModalPrivacy";

const Home = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [cek, setCek] = useState(false);
  const [modal, setModal] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchqr() {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/prodotti/qrorder",
        bodyParameters,
        config
      );
      setQrCode(res.data);
    }
    fetchqr();
  }, []);

  const toggleModal = async () => {
    setModal(!modal);
    console.log(modal);
  };
  const handleChange = async (e) => {
    setEmail(e.target.value);
  };
  const handleReset = async (e) => {
    setError("");
  };
  const handleCheck = async (event) => {
    setCek(!cek);
  };
  const handleSubmit = async () => {
    if (!cek || email == "") {
      setError("Controlla la mail o accetta la privacy ");
      return;
    }
    setFlag(true);

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        mail: email,
      };
      const res = await node.post(
        "/api/email/aggiungiemail",
        bodyParameters,
        config
      );

      setError("Il tuo indirizzo è stato salvato correttamente");
      setEmail("");
    } catch (error) {}

    setFlag(false);
  };

  return (
    <div className="container-home">
      {modal ? <Modal toggleModal={toggleModal} /> : null}
      <div className="margin50">
        <h2>Come Utilizzare il sistema?</h2>
        <div className="info">
          <div className="come_fare">
            <p>
              Naviga tra le categorie nel menu di sinistra e aggiungi i prodotti
              che desideri ordinare. Una volta che avete finito di ordinare,
              andate nel carrello e inviate l'ordine. Da quel momento in poi,
              tutto ciò che avete ordinato sarà preparato e servito. Se
              desiderate ordinare qualcosa che sarà servito successivamente,
              basta ripetere i passaggi precedenti
            </p>
            <h3>Semplice no?</h3>
          </div>
          <div className="qr_code_container">
            {qrCode && (
              <div className="qr">
                <img
                  className="qrim"
                  src={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                />
                <div className="testo_qr">
                  scansiona qui e aggiungi all'ordine direttamente dal tuo
                  telefono
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="raccolta">
        <div className="mail">
          Inserisci la tua mail per essere aggiornato sui nostri eventi, prezzi,
          offerte e coupon{" "}
        </div>
        <div className="spazio_input">
          <input
            id="first-name"
            className="campo_input"
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            onFocus={handleReset}
          />
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Email</span>
          </span>
          {error != "" ? <p>{error}</p> : null}
        </div>
        {error != "" ? <p>{error}</p> : null}
        <div className="spazio_input">
          <div className="check_privacy">
            <input
              className="check_box"
              type="checkbox"
              onChange={handleCheck}
              checked={cek ? true : false}
            />
            <span>
              <a href="#fakeLink" onClick={toggleModal}>
                Accetta la privacy
              </a>
            </span>
          </div>
          {!flag ? (
            <a
              href="#fakeLink"
              onClick={handleSubmit}
              className="btn purple"
              type="submit"
            >
              iscriviti
            </a>
          ) : (
            "Attendi"
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
