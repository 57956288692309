import { node } from "../../Axios";
import { getSession, removecarrello, setCarrello } from "../../utils/session";
import { logout, onClear } from "./login";

export const CARRELLO_START = "CARRELLO_START";
export const CARRELLO_SUCCESS = "CARRELLO_SUCCESS";
export const CARRELLO_FAIL = "CARRELLO_FAIL";

/* export const INSERT_CAR_START = "INSERT_CAR_START";
export const NSERT_CAR_SUCCESS = "NSERT_CAR_SUCCESS";
export const NSERT_CAR_FAIL = "NSERT_CAR_FAIL"; */
export const INCREMENTA_PRODOTTO = "INCREMENTA_PRODOTTO";
export const AGGIUNGI_PRODOTTO_CART = "AGGIUNGI_PRODOTTO_CART";
export const DECREMENTA_PRODOTTO = "DECREMENTA_PRODOTTO";
export const RECUPERA_CARRELLO = "RECUPERA_CARRELLO";

export const INIZIALIZE = "INIZIALIZE";
export const SET_COPERTI = "SET_COPERTI";

export const GET_PRODOTTI_START = "GET_PRODOTTI_START";
export const GET_PRODOTTI_SUCCESS = "GET_PRODOTTI_SUCCESS";
export const GET_PRODOTTI_FAIL = "GET_PRODOTTI_FAIL";

export const GET_INGREDIENTI_START = "GET_INGREDIENTI_START";
export const GET_INGREDIENTI_SUCCESS = "GET_INGREDIENTI_SUCCESS";
export const GET_INGREDIENTI_FAIL = "GET_INGREDIENTI_FAIL";

export const GET_OFFERTE_START = "GET_OFFERTE_START";
export const GET_OFFERTE_SUCCESS = "GET_OFFERTE_SUCCESS";
export const GET_OFFERTE_FAIL = "GET_OFFERTE_FAIL";

export const SEND_ORDINE_START = "SEND_ORDINE_START";
export const SEND_ORDINE_SUCCESS = "SEND_ORDINE_SUCCESS";
export const SEND_ORDINE_FAIL = "SEND_ORDINE_FAIL";

export const SET_TOTALE_GENERALE = "SET_TOTALE_GENERALE";
export const SET_TIPO = "SET_TIPO";
export const ISCARRELLO = "ISCARRELLO";
export const SET_STORICO = "SET_STORICO";
export const RESET_CARRELLO = "RESET_CARRELLO";


export const GET_STORICO_ORDINi_START = "GET_STORICO_ORDINi_START";
export const GET_STORICO_ORDINi_SUCCESS = "GET_STORICO_ORDINi_SUCCESS";
export const GET_STORICO_ORDINi_FAIL = "GET_STORICO_ORDINi_FAIL";

export const GET_FORMULE_START = "GET_FORMULE_START";
export const GET_FORMULE_SUCCESS = "GET_FORMULE_SUCCESS";
export const GET_FORMULE_FAIL = "GET_FORMULE_FAIL";
export const SET_STORICO_USCITE_ORDINE = "SET_STORICO_USCITE_ORDINE";

export const SET_TAVOLO = "SET_TAVOLO";
export const SET_IDORDINE = "SET_IDORDINE";
export const SET_IDFORMULA = "SET_IDFORMULA";
export const SET_MENU = "SET_MENU";




export const send_ordine = (carrello, totale, id_ordine, tipo) => {
  return async (dispatch) => {
    await dispatch(send_ordine_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        carrello: carrello,
        id_ordine: id_ordine,
        totale: totale.toFixed(2),
        tipo: tipo,
      };
      const res = await node.post(
        "/api/ordini/inviaordine",
        bodyParameters,
        config
      );
      await removecarrello();
      console.log(res);
      await dispatch(set_totale_generale(res.data.newtotale));

      await dispatch(send_ordine_success(res));
      await dispatch(
        set_storico_uscite_ordine(res.data.ordineaperto[0].storico_uscite)
      );
      await dispatch(set_storico_ordine_aperto(1));
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
    }
  };
};
export const send_ordine_start = () => {
  return {
    type: SEND_ORDINE_START,
  };
};
export const send_ordine_success = (res) => {
  return {
    type: SEND_ORDINE_SUCCESS,
    res,
  };
};
export const send_ordine_fail = () => {
  return {
    type: SEND_ORDINE_FAIL,
  };
};

export const getcarrello = () => {
  return async (dispatch) => {
    await dispatch(getcarrello_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getcarrello",
        bodyParameters,
        config
      );

      await dispatch(getcarrello_success(res));
    } catch (error) {
      await dispatch(get_carrello_fail());
    }
  };
};
export const getcarrello_start = () => {
  return {
    type: CARRELLO_START,
  };
};
export const getcarrello_success = (res) => {
  return {
    type: CARRELLO_SUCCESS,
    res,
  };
};
export const get_carrello_fail = () => {
  return {
    type: CARRELLO_FAIL,
  };
};
export const get_prodotti = (id, id_ordine) => {
  return async (dispatch,getState) => {
    await dispatch(get_prodotti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
        id_ordine: id_ordine,
        tipo:getState().ordini.tipo
      };
      const res = await node.post(
        "/api/prodotti/getprodottibycategoria",
        bodyParameters,
        config
      );
      console.log("Prodotti", res);
      await dispatch(get_prodotti_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
      await dispatch(logout());
    }
  };
};

export const recuperacarqr = () => {
  return async (dispatch) => {
    // await dispatch(get_prodotti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/recuperacarqr",
        bodyParameters,
        config
      );
      console.log(res);
      for (const card of res.data.ordineaperto[0].carrello_tmps) {
        let p = {
          id_prodotto: card.prodottiIdProdotto,
          nome: card.nome,
          special: card.special,
          ingredienti_it: card.ingredienti_it,
          ingredienti_en: card.ingredienti_en,
          note: card.nota,
          prezzo: card.prezzo,
          quantita: card.quantita,
          id_prodotto_interno: card.id_prodotto_interno,
          categoria: card.id_categoria,
          uscita: card.uscita,
        };
        await dispatch(aggiungi_prodotto_cart(p));
        // setCarrello(carrello)
        /*  console.log("carrello",carrello) */
        /* console.log(p) */
      }

      // await dispatch(get_prodotti_success(res));
    } catch (error) {
      // await dispatch(get_prodotti_fail());
      //  await dispatch(logout())
    }
  };
};

export const get_prodotti_start = () => {
  return {
    type: GET_PRODOTTI_START,
  };
};
export const get_prodotti_success = (res) => {
  return {
    type: GET_PRODOTTI_SUCCESS,
    res,
  };
};
export const get_prodotti_fail = () => {
  return {
    type: GET_PRODOTTI_FAIL,
  };
};

export const get_offerte = (id, id_ordine) => {
  return async (dispatch) => {
    await dispatch(get_offerte_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
        id_ordine: id_ordine,
      };
      const res = await node.post(
        "/api/prodotti/getofferte",
        bodyParameters,
        config
      );
      console.log("offerte", res);
      await dispatch(get_offerte_success(res));
    } catch (error) {
      await dispatch(get_offerte_fail());
      await dispatch(logout());
    }
  };
};
export const get_offerte_start = () => {
  return {
    type: GET_OFFERTE_START,
  };
};
export const get_offerte_success = (res) => {
  return {
    type: GET_OFFERTE_SUCCESS,
    res,
  };
};
export const get_offerte_fail = () => {
  return {
    type: GET_OFFERTE_FAIL,
  };
};

export const get_formule = (/* id */) => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/formule/getformule",
        bodyParameters,
        config
      );
      console.log("FORMULE", res);
      await dispatch(get_formule_success(res));
    } catch (error) {
      await dispatch(get_formule_fail());
    }
  };
};
export const get_formule_start = () => {
  return {
    type: GET_FORMULE_START,
  };
};
export const get_formule_success = (res) => {
  return {
    type: GET_FORMULE_SUCCESS,
    res,
  };
};
export const get_formule_fail = () => {
  return {
    type: GET_FORMULE_FAIL,
  };
};
export const get_storico_ordini = (/* id */) => {
  return async (dispatch) => {
    await dispatch(get_storico_ordini_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        /* id_categoria: id, */
      };
      const res = await node.post(
        "/api/ordini/getstoricoordine",
        bodyParameters,
        config
      );

      await dispatch(get_storico_ordini_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
      await dispatch(logout());
    }
  };
};

export const get_storico_ordini_start = () => {
  return {
    type: GET_STORICO_ORDINi_START,
  };
};
export const get_storico_ordini_success = (res) => {
  return {
    type: GET_STORICO_ORDINi_SUCCESS,
    res,
  };
};
export const get_storico_ordini_fail = () => {
  return {
    type: GET_STORICO_ORDINi_FAIL,
  };
};
export const get_ingredienti_antipasto_personalizzata = () => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperantipastopersonalizzata",
        bodyParameters,
        config
      );
      // console.log(res)
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_pizza_personalizzata = () => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpezzapersonalizzata",
        bodyParameters,
        config
      );
      console.log(res);
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_piadina_personalizzata = () => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpiadinapersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_hamburger_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperhamburgerpersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_nomi_pizza = () => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/prodotti/getnomipizze",
        bodyParameters,
        config
      );
      console.log(res);
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      console.log(error);

      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_start = () => {
  return {
    type: GET_INGREDIENTI_START,
  };
};
export const get_ingredienti_success = (res) => {
  return {
    type: GET_INGREDIENTI_SUCCESS,
    res,
  };
};
export const get_ingredienti_fail = () => {
  return {
    type: GET_INGREDIENTI_FAIL,
  };
};
export const inizialize = () => {
  return {
    type: INIZIALIZE,
  };
};
export const incrementa_prodotto = (id_prodotto_interno) => {
  return {
    type: INCREMENTA_PRODOTTO,
    id_prodotto_interno,
  };
};

export const decrementa_prodotto = (id_prodotto_interno) => {
  return {
    type: DECREMENTA_PRODOTTO,
    id_prodotto_interno,
  };
};
export const aggiungi_prodotto_cart = (card) => {
  return {
    type: AGGIUNGI_PRODOTTO_CART,
    card,
  };
};

export const recupera_carrello = (carrello, qty, totale, qty_bibite) => {
  return {
    type: RECUPERA_CARRELLO,
    carrello,
    qty,
    totale,
    qty_bibite,
  };
};
export const set_coperti = (coperti) => {
  return {
    type: SET_COPERTI,
    coperti,
  };
};
export const set_totale_generale = (tot_generale) => {
  return {
    type: SET_TOTALE_GENERALE,
    tot_generale,
  };
};
export const set_tipo = (tipo) => {
  return {
    type: SET_TIPO,
    tipo,
  };
};
export const set_iscarrello = (value) => {
  return {
    type: ISCARRELLO,
    value,
  };
};
export const set_storico_ordine_aperto = (value) => {
  return {
    type: SET_STORICO,
    value,
  };
};
export const set_tavolo = (value) => {
  return {
    type: SET_TAVOLO,
    value,
  };
};
export const set_id_ordine = (value) => {
  return {
    type: SET_IDORDINE,
    value,
  };
};
export const set_formula = (value) => {
  return {
    type: SET_IDFORMULA,
    value,
  };
};
export const togglenav = (value) => {
  return {
    type: SET_MENU,
    value,
  };
};
export const set_storico_uscite_ordine = (value) => {
  return {
    type: SET_STORICO_USCITE_ORDINE,
    value,
  };
};
export const reset_carrello = () => {
  return {
    type: RESET_CARRELLO,
    
  };
};
