import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { loading_login } from "../store/actions/login";
import {
  aggiungi_prodotto_cart,
  get_ingredienti,
} from "../store/actions/ordini";
import "../style/Stepper.css";
import { setCarrello } from "../utils/session";
import uuid from "react-uuid";
import HamburgerInfo from "../components/HamburgerInfo";
import BunsInfo from "../components/BunsInfo";
import CotturaInfo from "../components/CotturaInfo";
import SpecialiInfo from "../components/SpecialiInfo";
import SalseInfo from "../components/SalseInfo";
import FormaggiInfo from "../components/FormaggiInfo";
import VerdureInfo from "../components/VerdureInfo";
import SalumiInfo from "../components/SalumiInfo";
import ModalUscite from "../pages/ModalUscitePerStepper";

const HamburgerStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { id, uui } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const loading = useSelector((state) => state.ordini.loading);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);
  const storico_uscite = useSelector((state) => state.ordini.storico_uscite);

  const [modalUscite, setModalUscite] = useState(false);
  const [datiModal, seDatiModal] = useState({
    spe: "",
  });

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const [page, setPage] = useState(0);
  let base_pane = [];
  let base_carne = [];
  let base_cottura = [false, false, false];

  let basePane = [];
  let baseCarne = [];
  let baseCottura = [
    { id_ingredienti: 1, nome: "Al Sangue", prezzo: 0.0 },
    { id_ingredienti: 2, nome: "Media", prezzo: 0.0 },
    { id_ingredienti: 3, nome: "Ben Cotta", prezzo: 0.0 },
  ];

  ingredienti.forEach((element) => {
    if (
      element.categorie_ingredienti.nome == "Pane per Hamburger Personalizzato"
    ) {
      basePane.push(element);
      base_pane.push(false);
    }
  });
  ingredienti.forEach((element) => {
    if (element.categorie_ingredienti.nome == "Carne per Hamburger") {
      baseCarne.push(element);
      base_carne.push(false);
    }
  });

  const [formData, setFormData] = useState({
    nome: "",
    nota: "",
    base_pane: base_pane,
    base_carne: base_carne,
    base_cottura: base_cottura,
    pane: "",
    carne: "",
    cottura: "",
    salse: [],
    formaggi: [],
    verdure: [],
    salumi: [],
    special: [],
    ingredienti: [],
    totale: 0.0,
  });

  const FormTitles = [
    "Scegli il Pane",
    "Scegli la Carne",
    "Scegli la Cottura",
    "Scegli le Salse",
    "Scegli le Formaggi",
    "Scegli le Salumi",
    "Scegli le Verdure",
    "Scegli  Aggiunte Speciali",
  ];
  const modalpassUscite = () => {
    return (
      <ModalUscite
        toggleModalUscite={toggleModalUscite}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        ingredienti={ingredienti}
        setModalUscite={setModalUscite}
        modalUscite={modalUscite}
      />
    );
  };
  const toggleModalUscite = (card) => {
    setModalUscite(!modalUscite);
    seDatiModal({
      spe: 2,
    });
  };
  const handleIncrement = (spe, uscita = 0) => {
    /* dispatch(loading_login(true)); */
    let special = spe;
    let id_prodotto_interno = uuid();

    //console.log(formData)
    let descrizione = "Hamburger di " + formData.nome + ".";
    descrizione = descrizione + " -PANE: " + formData.pane;

    descrizione = descrizione + "  -CARNE: " + formData.carne;

    descrizione = descrizione + "  -COTTURA: " + formData.cottura;

    descrizione = descrizione + "  -SALSE: ";
    formData.salse.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -FORMAGGI: ";
    formData.formaggi.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -SALUMI: ";
    formData.salumi.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -VERDURE: ";
    formData.verdure.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -SPECIAL: ";
    formData.special.forEach((item, index) => {
      descrizione = descrizione + item.nome + " ,";
    });
    descrizione = descrizione + "  -NOTE: " + formData.nota;
    let prodotto = prodotti.find((pr) => pr.id_prodotto == id);
    if (!prodotto) {
      //TODO va gestita l'eccexione
      return;
    }
    const p = {
      id_prodotto: prodotto.id_prodotto,
      nome: "Hamburger Personalizzato",
      ingredienti_it: descrizione,
      ingredienti_en: "",
      note: formData.nota,
      prezzo: formData.totale,
      quantita: 1,
      special: special,
      uscita,
      id_prodotto_interno: id_prodotto_interno,
      categoria: prodotto.categorieIdCategoria,
    };

    dispatch(aggiungi_prodotto_cart(p));
    dispatch(loading_login(false));
    setModalUscite(!modalUscite);

    navigate("/carrello");
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <BunsInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 1) {
      return (
        <HamburgerInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 2) {
      return (
        <CotturaInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 3) {
      return (
        <SalseInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 4) {
      return (
        <FormaggiInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 5) {
      return (
        <SalumiInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else if (page === 6) {
      return (
        <VerdureInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    } else {
      return (
        <SpecialiInfo
          formData={formData}
          setFormData={setFormData}
          ingredienti={ingredienti}
          basePane={basePane}
          baseCarne={baseCarne}
          baseCottura={baseCottura}
        />
      );
    }
  };
  return (
    <div className="container-stepper">
      {modalUscite ? modalpassUscite() : null}

      <div className="progressbar">
        <div
          style={{
            width:
              page === 0
                ? "12.5%"
                : page == 1
                ? "25.0%"
                : page == 2
                ? "37.5%"
                : page == 3
                ? "50.0%"
                : page == 4
                ? "62.5%"
                : page == 5
                ? "75.0%"
                : page == 6
                ? "87.5%"
                : "100%",
          }}
        ></div>
      </div>
      <div className="titolo-page">{FormTitles[page]} </div>
      {loading ||
      formData.base_pane.length == 0 ||
      formData.base_carne.length == 0 ||
      formData.base_cottura.length == 0 ? (
        <Spinner />
      ) : (
        <div className="choice_container">{PageDisplay()}</div>
      )}
      <div className="ingredienti">{error != "" ? error : null}</div>

      <div className="pizza-btn-container">
        {page > 0 ? (
          <a
            href="#fakeLink"
            disabled={page == 0}
            onClick={() => {
              setPage((currPage) => currPage - 1);
              setError("");
            }}
            className="btn purple"
          >
            indietro
          </a>
        ) : null}
        <a
          href="#fakeLink"
          onClick={() => {
            if (page === FormTitles.length - 1) {
              if (
                formData.nome != "" /*  || */ &&
                formData.pane != "" &&
                formData.carne != "" &&
                formData.cottura != ""
              ) {
                if (storico_uscite == 1) {
                  handleIncrement(2);
                } else {
                  toggleModalUscite();
                }
                // handleIncrement(2);
                setError("");
              } else {
                setError(
                  "Il nome, il pane, la carne e la cottura sono obbligatori"
                );
              }
            } else {
              setPage((currPage) => currPage + 1);
              setError("");
            }
          }}
          className="btn purple"
        >
          {page === FormTitles.length - 1 ? "Conferma" : "Avanti"}
        </a>
      </div>
      <div className="totale-pizza">
        {" "}
        Per il tuo hamburger stai spendendo:{" "}
        {Number(formData.totale).toFixed(2)} Euro
      </div>
    </div>
  );
};

export default HamburgerStepper;
