import { node } from "../../Axios";
import {
  getSession,
  removeSession,
  setAttesaNuovo,
  setCarrello,
  setSession,
} from "../../utils/session";
import {
  aggiungi_prodotto_cart,
  get_formule,
  recuperacarqr,
  recupera_carrello,
  reset_carrello,
  set_coperti,
  set_formula,
  set_id_ordine,
  set_storico_ordine_aperto,
  set_storico_uscite_ordine,
  set_tavolo,
  set_tipo,
  set_totale_generale,
} from "./ordini";
import { setErrore } from "./useform";
import mqtt from "mqtt-browser";
import mqttTopics from "../../utils/topics";

export const DESTROY_SESSION = "DESTROY_SESSION";

export const IDRUOLO = "IDRUOLO";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GETMENU_START = "GETMENU_START";
export const GETMENU_SUCCESS = "GETMENU_SUCCESS";
export const GETMENU_FAIL = "GETMENU_FAIL";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const ATTESANUOVO = "ATTESANUOVO";
export const GET_CATEGORIE_START = "GET_CATEGORIE_START";
export const GET_CATEGORIE_SUCCESS = "GET_CATEGORIE_SUCCESS";
export const GET_CATEGORIE_FAIL = "GET_CATEGORIE_FAIL";
export const LOADING = "LOADING";

export const MQTT_CLIENT_DISCONNECTED = "MQTT_CLIENT_DISCONNECTED";
export const MQTT_CLIENT_CONNECTED = "MQTT_CLIENT_CONNECTED";
export const MQTT_CONNECTION_ERROR = "MQTT_CONNECTION_ERROR";

export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";

export const SET_MQTT_OPTIONS = "SET_MQTT_OPTIONS";

export const logini = (email, password) => {
  return async (dispatch) => {
    await dispatch(login_start());
    try {
      const res = await node.post("/api/login/login", {
        email: email,
        password: password,
        id_appcode: "486b2854-e7cb-410c-88e0-85e211f656f2",
      });
      console.log(res);
      await dispatch(login_success(res));

      if (res.data.ruoliutente.length > 1) {
        return true;
      } else if (res.data.ruoliutente.length == 1) {
        await dispatch(salvaRuoloScelto(res.data.id_ruolo));

        await dispatch(
          getmenui(
            res.data.id_persona,
            res.data.id_appcode,
            res.data.ruoliutente[0].id_ruolo
          )
        );
        if (res.data.ruoliutente[0].nome_ruolo === "Tablet") {
          await dispatch(get_categorie(res.data.categorie));
        }
      } else {
        await dispatch(setErrore("Utente senza ruoli"));
      }
    } catch (error) {
      dispatch(login_fail(error));
      if (error.response.status == "404") {
        await dispatch(setErrore(error.response.data.messaggio));
      }
      if (error.response.status == "422") {
        await dispatch(setErrore(error.response.data.messaggio));
      }
    }
  };
};

export const getmenui = (id_persona, appcode, value) => {
  return async (dispatch) => {
    await dispatch(getmenu_start());
    await dispatch(salvaRuoloScelto(value));

    try {
      const res = await node.post("/api/login/getmenu", {
        data: {
          id_persona: id_persona,
          id_appcode: appcode,
          id_ruolo: value,
        },
      });
      await setSession(res);
      await dispatch(attesanuovo());

      await dispatch(getmenu_success(res));
    } catch (error) {
      await dispatch(getmenu_fail());
    }
  };
};

export const logincheck = (sessione) => {
  return async (dispatch) => {
    dispatch(login_start());

    try {
      await dispatch({ type: LOGIN_CHECK, sessione });

      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post(
        "/api/login/checkautorization",
        bodyParameters,
        config
      );
      const mqttOptions = {
        broker: res.data.azienda.broker_url,
        username: res.data.azienda.usermqtt,
        password: res.data.azienda.passmqtt,
        port: res.data.azienda.portws,
        protocol: "wss",
      };
      const topics = mqttTopics.map(
        (topic) => `${res.data.azienda.basetopic}${topic}`
      );
      await dispatch({ type: FETCH_TOPICS_SUCCESS, payload: topics });
      await dispatch({
        type: SET_MQTT_OPTIONS,
        payload: mqttOptions,
      });

      await dispatch(attesanuovo());
      await dispatch(get_categorie());
      await dispatch(login_success(res));
      // dispatch(set_ordine(-1));
    } catch (error) {
      console.log(error);
      dispatch(login_fail());
      dispatch(logout());
      /* if (error.response.status == "401") {
          dispatch(logout());
        } */
    }
  };
};

export const login_start = () => {
  return {
    type: LOGIN_START,
  };
};
export const login_success = (res) => {
  return {
    type: LOGIN_SUCCESS,
    res,
  };
};
export const login_fail = () => {
  return {
    type: LOGIN_FAIL,
  };
};

export const getmenu_start = () => {
  return {
    type: GETMENU_START,
  };
};
export const getmenu_success = (res) => {
  return {
    type: GETMENU_SUCCESS,
    res,
  };
};
export const getmenu_fail = () => {
  return {
    type: GETMENU_FAIL,
  };
};

export const salvaRuoloScelto = (id_ruolo) => {
  return {
    type: IDRUOLO,
    id_ruolo,
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      await removeSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post("/api/login/logout", bodyParameters, config);
      await dispatch(disconnectMQTT());
    } catch (error) {
      dispatch(login_fail(error));
    }
    await dispatch(onClear());
  };
};

export const attesanuovo = () => {
  return async (dispatch) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post(
        "/api/login/attesanuovo",
        bodyParameters,
        config
      );
      console.log("attesa nuovo", res);
      if (res.data.tavoloaperto.length > 0) {
        await dispatch({ type: ATTESANUOVO, attesanuovo: true });
        await dispatch(
          set_coperti(
            res.data.tavoloaperto[0].ordini_tavolos[0].nume_persone +
              res.data.tavoloaperto[0].ordini_tavolos[0].nume_bambini
          )
        );
        await dispatch(set_totale_generale(res.data.tavoloaperto[0].totale));
        await dispatch(set_tipo(res.data.tavoloaperto[0].tipo));
        await dispatch(
          set_storico_ordine_aperto(res.data.tavoloaperto[0].storico)
        );
        await dispatch(
          set_storico_uscite_ordine(res.data.tavoloaperto[0].storico_uscite)
        );
        await dispatch(
          set_tavolo(res.data.tavoloaperto[0].ordini_tavolos[0].numero_tavolo)
        );
        await dispatch(set_id_ordine(res.data.tavoloaperto[0].id_ordine));
        await dispatch(
          set_formula(res.data.tavoloaperto[0].ordini_tavolos[0].id_formula)
        );

        await dispatch(get_categorie());
        await setAttesaNuovo(true);
      } else {
        await dispatch(get_formule());
        await dispatch({ type: ATTESANUOVO, attesanuovo: false });
        await setAttesaNuovo(false);
      }
      // await dispatch(login_success(res));
    } catch (error) {
      dispatch(logout());
      dispatch(login_fail(error));
      return;
    }
  };
};

export const get_categorie = () => {
  return async (dispatch) => {
    await dispatch(get_categorie_start());
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {};
      const res = await node.post(
        "/api/categorie/getcategorie",
        bodyParameters,
        config
      );
      await dispatch(get_categorie_success(res));
      console.log("CATEGORIE", res);
    } catch (error) {
      await dispatch(get_categorie_fail());
    }
  };
};
export const get_categorie_start = () => {
  return {
    type: GET_CATEGORIE_START,
  };
};
export const get_categorie_success = (res) => {
  return {
    type: GET_CATEGORIE_SUCCESS,
    res,
  };
};
export const get_categorie_fail = () => {
  return {
    type: GET_CATEGORIE_FAIL,
  };
};
export const loading_login = (value) => {
  return {
    type: LOADING,
    value,
  };
};
export const onClear = () => {
  return { type: DESTROY_SESSION };
};

export const connectMqtt = () => {
  return async (dispatch, getState) => {
    try {
      const mqttOptions = getState().login.mqttOptions;
      if (!mqttOptions) {
        // Gestisci il caso in cui mqttOptions sia nullo
        console.error("mqttOptions is null");
        return;
      }
      console.log("MQTT PRTION", mqttOptions);
      const client = mqtt.connect(mqttOptions.broker, {
        username: mqttOptions.username,
        password: mqttOptions.password,
        port: parseInt(mqttOptions.port),
        protocol: "wss",
      });

      client.on("connect", () => {
        console.log("Connesso al broker MQTT");
        // Puoi eseguire le operazioni desiderate qui, sicuro che il client sia connesso
      });

      client.on("error", async (error) => {
        console.error("Errore durante la connessione MQTT:", error);
      });
      await dispatch({ type: MQTT_CLIENT_CONNECTED, payload: client });
      if (getState().login.topics && Array.isArray(getState().login.topics)) {
        getState().login.topics.forEach((topic) => {
          client.subscribe(topic, (err) => {
            if (err) {
              console.error("MQTT subscribe error:", err);
            } else {
              console.log("Subscribed to topic:", topic);
            }
          });
        });
      }
      client.on("message", async (topic, message) => {
        if (topic === getState().login.topics[0]) {
          const sessione = await getSession();
          const obj = JSON.parse(message.toString());
          if (sessione.id == obj.id) {
            await dispatch(loading_login(true));
            await setCarrello([]);
            await dispatch(reset_carrello());

            await dispatch(attesanuovo());
            await dispatch(loading_login(false));
          }
        } else if (topic === getState().login.topics[1]) {
          await dispatch(recuperacarqr());
        } else if (topic === getState().login.topics[2]) {
          const obj = JSON.parse(message.toString());

          if (obj.id_ordine == getState().ordini.id_ordine) {
            await dispatch(set_coperti(obj.coperti));
            await dispatch(set_totale_generale(parseFloat(obj.totale)));
            await dispatch(set_storico_ordine_aperto(parseInt(obj.storico)));
            await dispatch(set_tavolo(obj.numero_tavolo));
            await dispatch(set_id_ordine(obj.id_ordine));
            await dispatch(set_formula(obj.id_formula));
          }
        } else if (topic === getState().login.topics[3]) {
          if (getState().login.id_ruolo == 4) {
            await dispatch(logout());
          }
        }
      });
    } catch (error) {
      console.log("Errore durante la connessione MQTT:", error);
      await dispatch({ type: MQTT_CONNECTION_ERROR, payload: error.message });
    }
  };
};
export const disconnectMQTT = () => {
  return (dispatch, getState) => {
    // Effettua la disconnessione dal broker MQTT
    getState().login.clinet &&
      getState().login.client.end(() => {
        // Esegui altre operazioni necessarie dopo la disconnessione
        dispatch({ type: MQTT_CLIENT_DISCONNECTED });
      });
  };
};
