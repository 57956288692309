import React from "react";
import { Outlet } from "react-router-dom";
import "../style/Layout.css";

import Navbar from "./Navbar";


const Layout = (props) => {

  return (
    <div className="container-layout">
      <div
        className="corpo">
        <Outlet />
      </div>
      <div className="subcontainer-layout">
        {props.isLogin && props.nuovo ? (
          <>
            <Navbar />
          </>
        ) : null}
      </div>
    </div>
  );
};
export default Layout;
