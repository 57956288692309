import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "../style/Navbar.css";
import Topbar from "./Topbar";
import logo from "../assets/logo_menu_manager.png";
import { togglenav } from "../store/actions/ordini";

const Navbar = () => {
  const dispatch = useDispatch();
  const categorie = useSelector((state) => state.login.categorie);
  const menu = useSelector((state) => state.login.menu);
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const id_formula = useSelector((state) => state.ordini.id_formula);
  const isLogin = useSelector((state) => state.login.isLogin);

  const toggle = () => {
    dispatch(togglenav(!togglemenu));
  };
  return (
    <nav className="nav">
      <div className="menu_icon_container">
        <img src={logo}></img>
        <div className="menu_icon" onClick={toggle}>
          <span
            className="material-symbols-rounded"
            style={{
              marginTop: togglemenu ? "0" : "-36px",
            }}
          >
            menu
          </span>
          <span className="material-symbols-rounded">close</span>
        </div>
      </div>
      <div
        className="content-nav"
        style={{
          display: togglemenu ? "none" : "block",
        }}
      >
        <div className="content-nav-block">
          {menu &&
            menu.map((voce, index) => (
              <NavLink
                to={`${voce.nome}/`}
                className="line"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#105c82" : null,
                })}
                key={index}
                onClick={toggle}
              >
                {voce.alias}
              </NavLink>
            ))}

          {/*   {categorie.map((cat, index) => (
          <NavLink
            to={`products/${cat.id_categoria}/${cat.tipo}`}
            className="line"
            key={index}
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#105c82" : null,
            })}
            onClick={toggle}
          >
            {cat.nome}
          </NavLink>
        ))} */}

          {categorie.map((cat, index) => {
            if (id_formula == 1) {
              return (
                <NavLink
                  to={`products/${cat.id_categoria}/${cat.tipo}`}
                  className="line"
                  key={index}
                  style={({ isActive }) => ({
                    color: isActive ? "#fff" : "#545e6f",
                    background: isActive ? "#105c82" : null,
                  })}
                  onClick={toggle}
                >
                  {cat.nome}
                </NavLink>
              );
            } else {
              if (cat.tipo == 0) {
                return (
                  <NavLink
                    to={`products/${cat.id_categoria}/${cat.tipo}`}
                    className="line"
                    key={index}
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "#545e6f",
                      background: isActive ? "#105c82" : null,
                    })}
                    onClick={toggle}
                  >
                    {cat.nome}
                  </NavLink>
                );
              }
            }
          })}
        </div>
      </div>
      {/* <NavLink to="/logout" className="logout active">
        Logout
      </NavLink> */}
    </nav>
  );
};
export default Navbar;
