import React from "react";
import validate from "../validators/validateInfo";
import useForm from "../validators/useForm";
import "../style/Form.css";
import "../style/Buttons.css";
import { useSelector } from "react-redux";
import { setErrore } from "../store/actions/useform";
import { useDispatch } from "react-redux";
import logo from "../logo.png";

const FormLogin = ({ submitForm, login }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate,
    login
  );
  const dispatch = useDispatch();

  const errore = useSelector((state) => state.useform.error);
  const handleReset = async () => {
    errors.email = "";
    errors.password = "";
    await dispatch(setErrore());
  };
  return (
    <div className="page_login" noValidate>
      <div className="logo_container">
        <img src={logo} className="logo_in_form" />
      </div>
      <div className="input_container">
        <div className="spazio_input">
          <input
            className="campo_input"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onFocus={handleReset}
          />
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Email</span>
          </span>
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className="spazio_input">
          <input
            className="campo_input"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onFocus={handleReset}
          />
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Password</span>
          </span>
          {errors.password && <p>{errors.password}</p>}
        </div>
        {errore && <p>{errore}</p>}
        {/* <button className="form-input-btn" type="submit">
        Login
      </button> */}
        <a
          href="#fakeLink"
          onClick={handleSubmit}
          className="btn"
          type="submit"
        >
          login
        </a>
      </div>
    </div>
  );
};

export default FormLogin;
