import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { node } from "../Axios";
import InputOrdine from "../components/InputOrdine";
import Spinner from "../components/Spinner";
import {
  attesanuovo,
  loading_login,
  logout,
  onClear,
} from "../store/actions/login";
import { get_formule } from "../store/actions/ordini";

import "../style/ApriTavolo.css";

import { getSession, removecarrello, removeSession } from "../utils/session";

function ApriTavolo({ nuovo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading2 = useSelector((state) => state.login.loading);

  const attesa = useSelector((state) => state.login.attesanuovo);
  const [numero_tavolo, setTavolo] = useState("");
  const [numero_bambini, setBambini] = useState("0");
  const [numero_persone, setPersone] = useState("0");
  const [id_formula, setIdformula] = useState("1");
  const [err_numero_tavolo, setTavoloErr] = useState("");
  const [err_numero_persone, setPersoneErr] = useState("");
  const formule = useSelector((state) => state.ordini.formule); 
 

  const nuovoordine = async () => {
    await dispatch(loading_login(true));
    
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {
        nume_persone: numero_persone,
        nume_bambini: numero_bambini,
        numero_tavolo: numero_tavolo,
        id_formula: id_formula,
        tipo:2
      };
      const res = await node.post(
        "/api/ordini/nuovoordinetablet",
        bodyParameters,
        config
      );

      await dispatch(attesanuovo());
      await dispatch(loading_login(false));
    } catch (error) {
      console.log(error);
      await dispatch(loading_login(false));
     
      if (error.response.status == "401") {
       
        dispatch(logout());
      }
      /* if (error.response.status == "422") {
        await dispatch(loading_login(false));
        console.log(error.response.data.error);
        console.log(error.response.data.error);
        setTavoloErr(
          error.response.data.error.find((p) => p.param == "numero_tavolo")
            .msg
        );
        setPersoneErr(
          error.response.data.error.find((p) => p.param == "nume_persone")
            .msg 
        );
      } */
    }
  };

  return (
    <div className="container-apri">
      <div className="login">
        {loading2 ? (
          <Spinner />
        ) : (
          <InputOrdine
            numero_tavolo={numero_tavolo}
            numero_persone={numero_persone}
            numero_bambini={numero_bambini}
            id_formula={id_formula}
            formule={formule}
            setTavolo={setTavolo}
            setPersone={setPersone}
            setBambini={setBambini}
            nuovoordine={nuovoordine}
            setIdformula={setIdformula}
        
          />
        )}

      </div>
    </div>
  );
}

export default ApriTavolo;
