import React from "react";
import "../style/Stepper.css";
import "../style/MySelect.css";

import "../style/Form.css";

const SelectPizza = ({
  formData,
  setFormData,
  categorieall,
  prezzo,
  setPrezzo,
  pizza1
}) => {
  const handleSelect = (e) => {
    setFormData({ ...formData, id_prodotto: e.target.value });
    let prodotto = categorieall.find((pr) => pr.id_prodotto == e.target.value);
    setPrezzo(prodotto.prezzo);
  };
  
  return (
    <div className="select">
      <select
        className="campo_input"
        aria-label="Default select example"
        onChange={(e) => handleSelect(e)}
        value={formData.id_prodotto}
      >
        <option value="-1">-- Seleziona una Pizza --</option>
        {categorieall.sort((a, b) => String(a.nome).localeCompare(b.nome)).map((categoria, index) => {
           /*  if(categoria.id_prodotto!=formData.id_prodotto){ */
            return (
              <option key={categoria.id_prodotto} value={categoria.id_prodotto}>
                {categoria.nome}
              </option>
            );/* } */
          })}
      </select>
    </div>
  );
};

export default SelectPizza;
