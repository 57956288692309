import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BaseInfo from "../components/BaseInfo";
import FornoInfo from "../components/FornoInfo";
import FuoriInfo from "../components/FuoriInfo";
import Spinner from "../components/Spinner";
import { loading_login } from "../store/actions/login";
import ModalUscite from "./ModalUscitePerStepper";

import {
  aggiungi_prodotto_cart,
  get_ingredienti,
} from "../store/actions/ordini";
import "../style/Stepper.css";
import "../style/Form.css";
import { setCarrello } from "../utils/session";
import uuid from "react-uuid";
import MetaInfo from "../components/SelectPizza";
import SelectPizza from "../components/SelectPizza";
import { setErrore } from "../store/actions/useform";

const FiftyStepper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, uui } = useParams();
  const carrello = useSelector((state) => state.ordini.carrello);
  const prodotti = useSelector((state) => state.ordini.prodotti);
  const loading = useSelector((state) => state.ordini.loading);
  const ingredienti = useSelector((state) => state.ordini.ingredienti);
  const id_formula = useSelector((state) => state.ordini.id_formula);
  const storico = useSelector((state) => state.ordini.sorico);
  const storico_uscite = useSelector((state) => state.ordini.storico_uscite);

  const [modalUscite, setModalUscite] = useState(false);
  const [datiModal, seDatiModal] = useState({
    spe: "",
  });

  useEffect(() => {
    setCarrello(carrello);
  }, [carrello]);

  const [pizza1, setPizza1] = useState({
    id_prodotto: "",
  });
  const [pizza2, setPizza2] = useState({
    id_prodotto: "",
  });
  const [nota, setNota] = useState("");
  const [error, setError] = useState("");
  const [prezzo, setPrezzo] = useState(0.0);
  const [prezzo2, setPrezzo2] = useState(0.0);
  const modalpassUscite = () => {
    return (
      <ModalUscite
        toggleModalUscite={toggleModalUscite}
        datiModal={datiModal}
        onIncrement={handleIncrement}
        ingredienti={ingredienti}
        setModalUscite={setModalUscite}
        modalUscite={modalUscite}
      />
    );
  };
  const toggleModalUscite = (card) => {
    setModalUscite(!modalUscite);
    seDatiModal({
      spe: 3,
    });
  };
  const handleIncrement = (spe, uscita = 0) => {
    dispatch(loading_login(true));
    let special = spe;

    let id_prodotto_interno = uuid();

    //console.log(formData)
    let descrizione = "";

    let prodotto = ingredienti.find(
      (pr) => pr.id_prodotto == pizza1.id_prodotto
    );
    let prodotto2 = ingredienti.find(
      (pr) => pr.id_prodotto == pizza2.id_prodotto
    );
    if (!prodotto && !prodotto2) {
      //TODO va gestita l'eccexione
      return;
    }
    descrizione = prodotto.nome + " + " + prodotto2.nome;
    const p = {
      id_prodotto: prodotto.id_prodotto,
      nome: "pizza Fifty Fifty",
      ingredienti_it: descrizione,
      ingredienti_en: "",
      note: nota,
      prezzo: parseFloat(Number(prezzo) + Number(prezzo2) / 2),
      quantita: 1,
      special: special,
      uscita,
      id_prodotto_interno: id_prodotto_interno,
      categoria: prodotto.categorieIdCategoria,
    };
    console.log("prodotto", p);

    dispatch(aggiungi_prodotto_cart(p));
    dispatch(loading_login(false));
    setModalUscite(!modalUscite);
    navigate("/carrello");
  };

  console.log("pizza 1", pizza1);
  console.log("pizza 2", pizza2);
  console.log("nota", nota);
  console.log("error", error);

  return (
    <div className="container-stepper">
      {modalUscite ? modalpassUscite() : null}
      <div className="pizze">
        <div className="titolo">Scegli le 2 metà</div>
        <div className="selectpizze">
          <SelectPizza
            formData={pizza1}
            setFormData={setPizza1}
            categorieall={ingredienti}
            prezzo={prezzo}
            setPrezzo={setPrezzo}
          ></SelectPizza>
          {
            <SelectPizza
              formData={pizza2}
              setFormData={setPizza2}
              categorieall={ingredienti}
              prezzo={prezzo2}
              setPrezzo={setPrezzo2}
            ></SelectPizza>
          }
        </div>
        <div className="spazio_input">
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Note</span>
          </span>
          <textarea
            className="campo_input"
            type="text"
            onChange={(e) => setNota(e.target.value)}
            value={nota}
          />
        </div>
      </div>
      {error != "" ? <div className="totale-pizza">{error}</div> : null}
      <div className="totale-pizza">
        <a
          href="#fakeLink"
          onClick={() => {
            pizza1.id_prodotto == "" && pizza2.id_prodotto == ""
              ? setError("Devi selezionare le pizze")
              : storico_uscite == 1
              ? handleIncrement(3)
              : toggleModalUscite();
          }}
          className="note_btn"
        >
          Aggiungi
        </a>
      </div>
      <div className="totale-pizza">
        {" "}
        Per la tua Pizza stai spendendo:{" "}
        {parseFloat(Number(prezzo) + Number(prezzo2) / 2).toFixed(2)} Euro
      </div>
    </div>
  );
};

export default FiftyStepper;
