import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import "./style/App.css";

import Products from "./pages/Products";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PrivateRoutes from "./components/PrivateRoutes";
import Layout from "./components/Layout";
import ApriTavolo from "./pages/ApriTavolo";
import NotFound from "./components/NotFound";
import Home from "./pages/Home";
import Storico from "./pages/Storico";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  ATTESANUOVO,
  attesanuovo,
  get_categorie,
  LOGIN_CHECK,
  logout,
  loading_login,
  onClear,
  attesanuovo2,
  connectMqtt,
  disconnectMQTT,
  fetchTopics,
  fetchMqttOptions,
  logincheck,
  login_fail,
} from "./store/actions/login";
import PersistLogin from "./components/PersistLogin";
import PersistLogin2 from "./components/PersistLogin2";
import {
  aggiungi_prodotto_cart,
  recuperacarqr,
  recupera_carrello,
  set_coperti,
  set_formula,
  set_id_ordine,
  set_storico_ordine_aperto,
  set_tavolo,
  set_totale_generale,
} from "./store/actions/ordini";
import {
  getSession,
  inizializzastorage,
  setAttesaNuovo,
  setCarrello,
} from "./utils/session";
import { Navigate } from "react-router-dom";
import { node } from "./Axios";
import Carrello from "./pages/Carrello";
import Spinner from "./components/Spinner";
import PizzaStepper from "./pages/PizzaStepper";
import HamburgerStepper from "./pages/HamburgerStepper";
import PiadinaStepper from "./pages/PiadinaStepper";
import FiftyStepper from "./pages/FiftyStepper";

import mqtt from "mqtt-browser";

function App() {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const [controllocart, setControlloCart] = useState(false);
  const isLogin = useSelector((state) => state.login.isLogin);
  const loading = useSelector((state) => state.login.loading);
  const nuovo = useSelector((state) => state.login.attesanuovo);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);

  const token = useSelector((state) => state.login.token);
  const sessione = JSON.parse(localStorage.getItem("SESSIONE"));
  const topics = useSelector((state) => state.login.topics);
  const mqttOptions = useSelector((state) => state.login.mqttOptions);
  const client = useSelector((state) => state.login.client);

  useEffect(() => {
    async function fetch_recupera_carrello_locale() {
      try {
        let items = JSON.parse(localStorage.getItem("CARRELLO"));
        if (items && items.length > 0) {
          let qty = 0;
          let totale = 0.0;
          let qty_bibite = 0;
          items.forEach((item, index) => {
            qty_bibite =
              item.categoria == 1 && item.prezzo == 0
                ? qty_bibite + item.quantita
                : qty_bibite;
            qty = qty + item.quantita;
            totale = totale + item.quantita * item.prezzo;
          });

          await dispatch(recupera_carrello(items, qty, totale, qty_bibite));
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetch_recupera_carrello_locale();
  }, []);

  useEffect(() => {
    const fetchLogin = async () => {
      console.log("recupero i parametri");
      await dispatch(logincheck(sessione));
    };

    if (sessione && sessione.token !== "" && token === "") {
      fetchLogin();
    }
  }, [dispatch, sessione, token]);

  useEffect(() => {
    const fetchConnectMQTT = async () => {
      try {
        await dispatch(connectMqtt());
      } catch (error) {
        console.log("Errore durante il recupero dei dati:", error);
      }
    };

    if (isLogin && mqttOptions && topics && !client) {
      fetchConnectMQTT();
    }
  }, [isLogin, topics, mqttOptions, client, dispatch]);

  return (
    <div className="App">
      {loading ? (
        <Spinner />
      ) : (
        <Router>
          <Routes>
            {
              <Route
                path="/"
                element={<Layout isLogin={isLogin} nuovo={nuovo} />}
              >
                <Route
                  path="login"
                  element={<Login isLogin={isLogin} nuovo={nuovo} exact />}
                />
                <Route path="*" element={<NotFound />} />
                <Route element={<PrivateRoutes isLogin={isLogin} />}>
                  <Route element={<Logout />} path="/logout" />
                  <Route element={<PersistLogin nuovo={nuovo} />}>
                    <Route
                      element={<ApriTavolo nuovo={nuovo} />}
                      path="/apritavolo"
                    />
                  </Route>
                  <Route element={<PersistLogin2 nuovo={nuovo} />}>
                    <Route element={<Home />} path="/" exact />
                    <Route element={<Storico />} path="/storico" exact />

                    <Route
                      element={<PizzaStepper />}
                      path="/pizzastepper/:id/:uui"
                      exact
                    />
                    <Route
                      element={<PiadinaStepper />}
                      path="/piadinastepper/:id/:uui"
                      exact
                    />
                    <Route
                      element={<HamburgerStepper />}
                      path="/hamburgerstepper/:id/:uui"
                      exact
                    />
                     <Route
                    element={<FiftyStepper />}
                    path="/fiftyStepper/:id/:uui"
                    exact
                  />
                    <Route element={<Products />} path="/products/:id/:flag" />
                    <Route element={<Carrello />} path="/carrello" />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
            }
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
