import React, { useState } from "react";
import "../style/Modal.css";
import "../style/ModalUscite.css";

const Modal = (props) => {
  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div
            className="menu-close"
            onClick={() => props.setModalUscite(!props.modalUscite)}
          >
            <span className="material-symbols-rounded">close</span>
          </div>
          <div className="titolo" onClick={() => props.toggleModalUscite()}>
            Quando vuoi che esca?
          </div>
          <div className="uscite">
           
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() => props.onIncrement(props.datiModal.spe, 1)}
                className="btn purple"
              >
                I° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() => props.onIncrement(props.datiModal.spe, 2)}
                className="btn purple"
              >
                II° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() => props.onIncrement(props.datiModal.spe, 3)}
                className="btn purple"
              >
                III° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() => props.onIncrement(props.datiModal.spe, 4)}
                className="btn purple"
              >
                IV° uscita
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
