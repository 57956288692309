import React, { useState } from "react";
import "../style/Modal.css";
import "../style/ModalUscite.css";

const Modal = (props) => {
  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div
            className="menu-close"
            onClick={() => props.setModalUscite(!props.modalUscite)}
          >
            <span className="material-symbols-rounded">close</span>
          </div>
          <div
            className="titolo black"
            onClick={() => props.toggleModalUscite()}
          >
            Quando vuoi che esca?
          </div>
          <div className="uscite">
           
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.datiModal.card,
                    props.datiModal.note,
                    props.datiModal.formData,
                    1
                  )
                }
                className="btn full-width"
              >
                I° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.datiModal.card,
                    props.datiModal.note,
                    props.datiModal.formData,
                    2
                  )
                }
                className="btn full-width"
              >
                II° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.datiModal.card,
                    props.datiModal.note,
                    props.datiModal.formData,
                    3
                  )
                }
                className="btn full-width"
              >
                III° uscita
              </a>
            </div>
            <div className="uscita">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.datiModal.card,
                    props.datiModal.note,
                    props.datiModal.formData,
                    4
                  )
                }
                className="btn full-width"
              >
                IV° uscita
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
