import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../style/InputOrdine.css";
import "../style/Buttons.css";
import "../style/Form.css";
import { getSession } from "../utils/session";
import SelectFormule from "./SelectFormule";

const InputOrdine = (props) => {
  return (
    <div className="page_apri_tavolo">
        <div className="input_container">
          {props.formule.length > 1 ? (
            <div className="spazio_input">
              <SelectFormule
                onValue={props.setIdformula}
                value={props.id_formula}
                formule={props.formule}
                className="campo_input"
              />
            </div>
          ) : null}
          {props.id_formula != "-1" ? (
            <>
              <div className="spazio_input">
                <input
                  id="first-name"
                  type="number"
                  className="campo_input"
                  placeholder="Inserisci il numero del tavolo"
                  onChange={(e) => props.setTavolo(e.target.value)}
                  value={props.numero_tavolo}
                />
                <span className="campo_label" aria-hidden="true">
                  <span className="field__label">Tavolo</span>
                </span>
              </div>
              <div className="spazio_input">
                <input
                  id="last-name"
                  type="number"
                  className="campo_input"
                  placeholder="Inserisci il numero dei commensali"
                  onChange={(e) => props.setPersone(e.target.value)}
                  value={props.numero_persone}
                />
                <span className="campo_label" aria-hidden="true">
                  <span className="field__label">
                    {props.id_formula != "1" ? "Adulti" : "Persone"}
                  </span>
                </span>
              </div>
            </>
          ) : null}
          {props.id_formula != "1" && props.id_formula != "-1" ? (
            <div className="spazio_input">
            <input
              id="last-name"
              type="number"
              className="campo_input"
                placeholder="Inserisci il numero dei commensali"
                onChange={(e) => props.setBambini(e.target.value)}
                value={props.numero_bambini}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Bambini</span>
              </span>
            </div>
          ) : null}
        </div>
      {props.id_formula != "-1" ? (
        <a
          href="#fakeLink"
          onClick={props.nuovoordine}
          className="btn purple"
          disabled={true}
        >
          Apri Tavolo
        </a>
      ) : null}
    </div>
  );
};

export default InputOrdine;
