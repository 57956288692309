import React, { useState } from "react";
import "../style/Cards.css";

const Cards = (props) => {
  let prezzo =
    props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined
      ? Number(props.card.prezzo_offerte)
      : Number(props.card.prezzo);
  const [colore, setColore] = useState(
    props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined
      ? "red"
      : "black"
  );
  const [taglio, setTaglio] = useState(
    props.card.prezzo_offerte != 0.0 && props.card.prezzo_offerte !== undefined
      ? "line-through"
      : "none"
  );
  /* console.log("uid",props.id_prodotto_interno) */
  return (
    <div className="card">
      <a
        href="#fakeLink"
        onClick={() =>
          props.toggleModal(
            props.card,
            props.onqty,
            props.id_prodotto_interno,
            prezzo
          )
        }
      >
        <div className="titolo">{props.card.nome}</div>
        <img className="image" src={props.card.path} alt="BigCo Inc. logo" />
      </a>
      <div className="conenitore-info">
        <div className="prezzi">
          <div
            className="prezzo"
            style={{
              color: colore,
              textDecoration: taglio,
            }}
          >
            {" "}
            {Number(props.card.prezzo).toFixed(2)} €
          </div>

          {props.card.prezzo_offerte !== 0.0 &&
          props.card.prezzo_offerte !== undefined ? (
            <div className="prezzo">
              {" "}
              {Number(props.card.prezzo_offerte).toFixed(2)} €
            </div>
          ) : null}
        </div>
        <div className="pulsanti">
          <div className="dec">
            <a
              href="#fakeLink"
              onClick={
                props.id_formula !== 1 || props.storico_uscite == 1
                  ? () => props.onIncrement(props.card, "")
                  : () => props.toggleModalUscite(props.card)
              }
              className="note_btn"
            >
              +
            </a>
          </div>
          <div className="qty">{props.onqty}</div>
          {props.id_formula == 1 ? (
            <div className="inc">
              <a
                href="#fakeLink"
                onClick={() => props.toggleModalNota(props.card)}
                className="note_btn"
              >
                + nota
              </a>
            </div>
          ) : <div></div>}
        </div>
      </div>
    </div>
  );
};

export default Cards;
