const topics = [
    '/ordini/chiudi_ordine',
    '/ordini/aggiorna_dati_ordine_tablet',
    '/ordini/aggiornati_dati_in_carrello',
    '/user_tablet/logout_massivo',



    
  ];
  
  module.exports = topics;