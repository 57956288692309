import React from "react";
import "../style/BaseInfo.css";
import RadioButton from "./RadioButton";

const PiadinaInfo = (props) => {
  let theme= props.formData.base_piadina;

  const onChangeTheme = (e) => {
    const { id } = e.target;
    const pos = props.basePiadina.findIndex((p) => p.id_ingredienti == id);

    theme.forEach((element, index) => {

      if (pos === index) {
        theme[index] = true;
      } else {
        theme[index] = false;
      }
    });

    props.setFormData({
      ...props.formData,
      totale:Number(props.formData.totale)+ Number(props.basePiadina[pos].prezzo),
      base_piadina: theme,
      piadina: e.target.value,

    });



  };

  return (
    <div className="baseinfo-container">
      <div className="input_container">
        <div className="spazio_input vertical">
          <span className="field__label-wrap" aria-hidden="true">
            <span className="field__label">Nome</span>
          </span>
          <input
            className="campo_input"
            type="text"
            placeholder="Scrivi il tuo nome"
            required
            onChange={(e) =>
              props.setFormData({ ...props.formData, nome: e.target.value })
            }
            value={props.formData.nome}
          />
        </div>
      </div>
      {props.basePiadina.map((item, index) => {

          return (
            <RadioButton
              key={index}
              id={item.id_ingredienti}
              value={item.nome}
              prezzo={item.prezzo}
              text={item.nome}
              onChange={onChangeTheme}
              checked={theme[index]}
            />
          );
        
      })}
    </div>
  );
};

export default PiadinaInfo;
